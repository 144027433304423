default-shadow = 0 2px 3px 0 rgba(69, 7, 69, 0.2)
secondary-shadow = 0 6px 20px 0 rgba(69, 7, 69, 0.12)

border-linear = linear-gradient(transparent 80%, rgba(248, 230, 2, 1) 0)

step($color = primary-color)
  font-family Overpass
  font-weight bold
  width 80px
  margin 0 auto
  border-radius 12px
  padding 8px 0
  font-size-px(font-size, 14)
  letter-spacing 1.4px
  background $color
  color #fff
  position relative
  &::before
    content ""
    position absolute
    top: 100%
    left: 50%
    margin-left -8px
    border 8px solid transparent
    border-top 8px solid $color

icon($size = 32)
  $margin = $size / 2
  font-family Overpass
  font-weight bold
  width unit($size, 'px')
  height unit($size, 'px')
  min-width unit($size, 'px')
  display block
  border-radius 100%
  text-align center
  line-height unit($size, 'px')
  font-size 14px
  color white
  margin-right unit($margin, 'px')
