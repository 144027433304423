html
  scroll-behavior smooth
  font-size 62.5%
body
  font-family NotoSansCJKjp
  color primary-color

h1
  font-family NotoSansCJKjp
  font-weight 700
  font-size-px(font-size, 24)
  color primary-text
  .section-title
    font-size-px(font-size, 24)

h2
  font-family NotoSansCJKjp
  font-weight 700
  font-size-px(font-size, 18)
  color primary-text

h3
  font-family NotoSansCJKjp
  font-weight 700
  font-size-px(font-size, 15)
  color primary-text

h4
  font-family NotoSansCJKjp
  font-weight 500
  font-size-px(font-size, 12)
  color primary-text

p
  font-family NotoSansCJKjp
  font-size-px(font-size, 15)
  line-height 25px
  color primary-text

.container
  max-width 1280px
  &.main
    max-width 1140px

strong
  font-family Overpass
  color primary-text
  font-weight bold
  margin-right 0.5rem
  &.border
    background border-linear
    background-position 0 -3px
  &.brand
    font-family 'Raleway', sans-serif
  &.small
    font-size-px(font-size, 16)
  &.middle
    font-size-px(font-size, 22)
  &.large
    font-size-px(font-size, 28)

.title
  font-size-px(font-size, 24)
  color primary-text
  margin-bottom 20px
  +breakpoint(sm)
    margin-bottom 40px

.heading-title
  font-size-px(font-size, 14)
  font-family Overpass
  font-weight bold
  text-transform uppercase
  letter-spacing 1.4px
  color primary-color
  margin-bottom 20px
  display block

.notice
  font-size-px(font-size, 12)
  line-height 1.5
  color secondary-text

.emphasis
  position relative
  &::before
    position absolute
    content ""
    display block
    background primary-text
    width 34px
    height 1px
    transform rotate(48deg)
    left 24%
    top 24px
    +breakpoint(md)
      left adjust-pc(120)
    +breakpoint(sm)
      left adjust-sp(100)
    +breakpoint(xs)
      left 50px
  &::after
    position absolute
    content ""
    display block
    background primary-text
    width 34px
    height 1px
    transform rotate(-48deg)
    right 24%
    top 24px
    +breakpoint(md)
      right adjust-pc(120)
    +breakpoint(sm)
      right adjust-sp(100)
    +breakpoint(xs)
      right 50px

.section-bg
  background bg-light-blue
  background-size cover

.primary-button
  background primary-color
  border 0
  color white
  padding 1.4rem 2rem
  border-radius 12px
  font-weight 700
  font-size-px(font-size, 16)
  height 48px
  transition all 0.2s linear
  &:hover
    color white
    background secondary-button-hover
  &:active
    color white
    background secondary-button-active
  &:disabled
    color white
    background-color secondary-button-disabled

.secondary-button
  background white
  width 100%
  border 2px solid primary-color
  color primary-color
  padding 1.4rem 2rem
  border-radius 12px
  font-weight bold
  font-size-px(font-size, 14)
  height 40px
  transition all 0.2s linear
  &:hover
    border 2px solid secondary-button-hover
    color secondary-button-hover
    background secondary-button-hover-bg
  &:active
    border 2px solid secondary-button-active
    color secondary-button-active
    background secondary-button-active-bg

.copyright
  font-size-px(font-size, 8)
  font-family Overpass
  font-weight bold
  color secondary-text

.header
  min-height 80px
  width 100%
  position fixed
  left 0
  top 0
  z-index 1000
  background white
  +breakpoint(sm)
    min-height adjust-sp(60)
    height adjust-sp(60)
  &-banner
    background primary-color
    text-align center
    padding 8px 16px
    p
      color white
      font-weight 500
      font-size-px(font-size, 12)
      font-feature-settings "palt"
      .pc-only
        +breakpoint(sm)
          display none
  .navbar
    width 100%
    height 100%
    min-height 80px
    display flex
    align-items center
    justify-content center
    +breakpoint(sm)
      flex-wrap nowrap
      min-height adjust-sp(60)
    >.container
      +breakpoint(sm)
        min-height adjust-sp(60)
    &-item
      font-weight 700
      cursor pointer
      padding 24px
      -webkit-tap-highlight-color rgba(0, 0, 0, 0)
      +breakpoint(sm)
        padding 0
      &.search
        margin-right 12px
        +breakpoint(sm)
          margin-right 0
          z-index 0
        svg
          +breakpoint(sm)
            width 24px
      &.account
        margin-right 12px
        +breakpoint(sm)
          margin-right 0
          display flex
          align-items center
          z-index -1
        svg
          +breakpoint(sm)
            width 24px
      &.cart
        +breakpoint(sm)
          display flex
          align-items center
          z-index -1
        svg
          +breakpoint(sm)
            width 24px
    &-burger
      +breakpoint(sm)
        display block
        min-height adjust-sp(60)
    &-brand
      padding 0
      +breakpoint(sm)
        min-height adjust-sp(60)
        padding 0 adjust-sp(16)
      .navbar-item
        min-width 180px
        display flex
        flex-direction row
        align-items center
        margin 0
        transition 100ms ease-out
        background none
        &:hover
          opacity .6
        +breakpoint(sm)
          width 100%
          max-width adjust-sp(130)
        img
          max-width none
          width 100%
          max-height 32px
          +breakpoint(sm)
            width adjust-sp(130)
        h1
          display block
          margin 0 18px
          +breakpoint(sm)
            margin 0
            width 100%
          img
            width 100%
            max-height 100%
            +breakpoint(sm)
              max-height 100%
              height 100%
        h2
          font-family 'A1 Gothic B'
          line-height 1.47
          color text-grey
          font-size 14px
          margin-bottom 8px
          +breakpoint(sm)
            display none
    &-menu
      display flex
      box-shadow none
      +breakpoint(md)
        display none
        opacity 0
        &.is-active
          border-radius 0
          background-color primary-color
          width 100%
          height 100%
          position fixed
          display block
          opacity 1
          right 0
          top 0
          z-index 1001
          animation menuIn .24s ease both
          +breakpoint(sm)
            animation menuInSp .24s ease both
          .navbar
            &-end
              display flex
              flex-direction column
              height 100%
              justify-content center
              align-items center
              padding adjust-sp(240) 0
            &-item
              color white
              background none
              opacity 0
              font-size-px(font-size, 24)
              margin-bottom 24px
              for row in 1..6
                &:nth-child({row})
                  animation slideIn .24s linear row * .1s forwards
      +breakpoint(sm)
        background-color transparent
        box-shadow none
        padding 0
        margin-left auto
      .search
        position relative
        &-result
          position absolute
          top 70px
      .account
        position relative
        &-menu
          position absolute
          top 70px
          left -100px
          +breakpoint(sm)
            position fixed
            top 0 !important
            left 0 !important
            z-index 20
      .cart
        position relative
        &.is-adding
          animation poyooon 0.5s linear 0s 1
        &-menu
          position absolute
          top 70px
          left -95px
          +breakpoint(sm)
            position fixed
            top 0 !important
            left 0 !important
        &-count
          position absolute
          right 0
          top 0
          background-color primary-red
          width 18px
          height 18px
          color white
          text-align center
          line-height 18px
          border-radius 50%
          box-shadow 0 0 0 2px white
          font-size-px(font-size, 10)
          font-family NotoSansCJKjp
          font-style normal
          +breakpoint(sm)
            right -6px
            top 3px
    &-end
      display flex
  .notification
    position fixed
    text-align center
    bottom 24px
    left 50%
    transform translateX(-50%)
    z-index 1000
    font-size-px(font-size, 16)
    font-family NotoSansCJKjp
    opacity 1
    border-radius 8px
    +breakpoint(sm)
      width 92%
    &.is-success
      background-color primary-blue
      box-shadow 0 4px 24px 0 rgba(108, 203, 230, 0.32)
    &.is-warning
      background-color primary-yellow
    &.is-danger
      background-color primary-red
      box-shadow 0 4px 24px 0 rgba(255, 119, 119, 0.32)
