.hero
  padding-top 80px
  position relative
  overflow hidden
  +breakpoint(sm)
    padding-top adjust-sp(114)
  &.is-fullheight
    min-height adjust-pc(931)
    +breakpoint(sm)
      min-height adjust-sp(510)
  &.is-info
    background-size cover
    background-color bg-light-blue
  &::after
    content ""
    position absolute
    z-index 100
    background-image url("/assets/img/hero-after-curve.svg")
    background-size cover
    width 100%
    height adjust-pc(140)
    bottom -1px
    left 0
    +breakpoint(sm)
      background-image url("/assets/img/hero-after-curve-mobile.svg")
      height adjust-sp(52)
  .hero-body
    align-items stretch !important
  .brand-container
    width adjust-pc(340)
    display flex
    flex-direction column
    margin-top adjust-pc(60)
    margin-left adjust-pc(60)
    +breakpoint(sm)
      width adjust-sp(215)
      margin-top adjust-sp(-32)
      margin-left 0
    h1
      width adjust-pc(420)
      margin-bottom adjust-pc(23)
      text-align left
      img
        width 100%
      +breakpoint(sm)
        width adjust-sp(240)
        margin-bottom adjust-sp(10)
    .brand-container-item
      width adjust-pc(328)
      display flex
      margin-bottom adjust-pc(23)
      +breakpoint(sm)
        width adjust-sp(215)
        margin-bottom adjust-sp(20)
      &:hover
        background-color transparent !important
      .logo-symbol
        margin-top adjust-pc(10)
        width adjust-pc(70)
        height adjust-pc(70)
        display block
        +breakpoint(sm)
          width adjust-sp(45)
          height adjust-sp(45)
        img
          height 100%
          max-height 100%
          object-fit contain
      .logo-text
        width adjust-pc(230)
        height adjust-pc(84)
        margin-left adjust-pc(26)
        +breakpoint(sm)
          width adjust-sp(151)
          height adjust-sp(55)
          margin-left adjust-sp(18)
        img
          height 100%
          max-height 100%
          object-fit contain
    .badge
      border-radius adjust-pc(18)
      width adjust-pc(328)
      padding adjust-pc(12) adjust-pc(24)
      background-color white
      z-index 9
      img
        width adjust-pc(175)
      +breakpoint(sm)
        //box-shadow default-shadow
        width adjust-sp(210)
        padding adjust-sp(10) 0
        border-radius adjust-sp(12)
        img
          width adjust-sp(114)
  .main-visual
    width adjust-pc(610)
    position absolute
    bottom adjust-pc(-20)
    z-index 10
    left 50%
    margin-left adjust-pc(-200)
    img
      width 100%
    +breakpoint(sm)
      width adjust-sp(273)
      margin-left adjust-sp(-135)
      bottom adjust-sp(-44)
  .navigation
    position absolute
    z-index 100
    right adjust-pc(120)
    bottom adjust-pc(150)
    +breakpoint(sm)
      width 100%
      height 100%
      left 0
      top 0
    &-inner
      position relative
      +breakpoint(sm)
        width 100%
        height 100%
        left 0
        top 0
    &-sales-balloon
      box-shadow default-shadow
      width adjust-pc(252)
      height adjust-pc(252)
      border-radius 100%
      background-color white
      display flex
      flex-direction column
      justify-content center
      align-items center
      .notice
        font-weight 500
        font-size-px(font-size, 10)
        +breakpoint(sm)
          padding 0 adjust-sp(18)
      img
        width adjust-pc(164)
      +breakpoint(sm)
        width adjust-sp(135)
        height adjust-sp(135)
        bottom adjust-sp(40)
        right adjust-sp(-3)
        position absolute
        img
          width adjust-sp(87)
    &-balloon
      position absolute
      top adjust-pc(-50)
      right adjust-pc(-50)
      width adjust-pc(112)
      height adjust-pc(112)
      border-radius 100%
      background-color primary-color
      display flex
      justify-content center
      align-items center
      flex-direction column
      @extend .primary-button
      +breakpoint(sm)
        width adjust-sp(108)
        height adjust-sp(108)
        top adjust-sp(90)
        right adjust-sp(-4)
      p
        color white
        font-size adjust-pc(12)
        line-height adjust-pc(24)
        font-weight bold
        margin-bottom 0.8rem
        +breakpoint(sm)
          font-size adjust-sp(12)
          line-height adjust-sp(16)
      &.button
        border 0
  .scroll-bg
    position absolute
    width 240%
    height 240%
    left -10%
    top adjust-pc(100)
    overflow hidden
    transform rotate(-18deg)
    +breakpoint(sm)
      top 72%
      margin-top adjust-sp(-160)
    .to-left, .to-right
      display block
      height adjust-pc(180)
      transform translateX(adjust-pc(200))
      margin-bottom adjust-pc(20)
      retina-bg("/assets/img/items-desktop")
      background-repeat repeat-x
      background-size 100%
      background-position 0 0
      +breakpoint(sm)
        height adjust-sp(80)
      img
        max-width none
        height 100%
    .to-left
      animation toLeftScroll 60s linear infinite
      +breakpoint(sm)
        margin-bottom adjust-sp(10)
    .to-right
      background-position -2900px 0
      animation toRightScroll 60s linear infinite
    .cards-desktop
      display block
      +breakpoint(sm)
        display none
    .cards-mobile
      display none
      +breakpoint(sm)
        display block
#about
  margin-top -120px
  z-index 999
  +breakpoint(sm)
    margin-top -20px
  .box
    box-shadow secondary-shadow
    padding 44px 124px
    opacity .9
    +breakpoint(sm)
      margin 0 10px
      padding 34px
      text-align center
    .columns
      .column
        &.image
          +breakpoint(sm)
            display none
        &.description
          display flex
          flex-direction column
          justify-content center
          margin-left 24px
          +breakpoint(sm)
            margin-left 0
          h2
            margin-bottom 1.6rem
          p
            font-size-px(font-size, 15)
            line-height 2.6rem
            font-weight bold
#merit
  &.section
    padding 80px 1rem 3rem
  .columns
    padding 2rem
    +breakpoint(sm)
      padding 1rem 0.25rem
    .column
      padding 20px
      +breakpoint(sm)
        padding 15px 5px
      &:nth-child(1)
        .card
          background bg-dark-blue
      &:nth-child(2)
        .card
          background bg-dark-blue
      &:nth-child(3)
        .card
          background bg-dark-blue
    h3
      margin 2.4rem 0 0
  .media
    .media-content
      .title
        color primary-text
        font-weight bold
        font-size-px(font-size, 18)
        line-height 1.56
        text-align center
        margin 0 0 1rem
      .notice
        @extend .notice
        font-size-px(font-size, 10)
        line-height 1.5
        text-align center
  .card
    height 100%
    border-radius 12px
    +breakpoint(sm)
      margin-bottom 1.5rem
    .card-content
      padding 1.5rem 0 0
      display flex
      height 100%
      flex-wrap nowrap
      flex-direction column
      justify-content space-between
  .card-title
    margin-top -3rem !important
    &.step-1
      step(primary-color)
    &.step-2
      step(primary-color)
    &.step-3
      step(primary-color)
  .content
    figure
      align-items center
      justify-content center
      display flex
      img
        display block
        max-width 200px
  .movie
    max-width 720px
    min-height 404px
    margin 0 auto
    +breakpoint(sm)
      min-height auto
    &-poster
      border-radius 12px
      overflow hidden
      position relative
      cursor pointer
      height auto
      &::before
        content ""
        position absolute
        background #000
        opacity .24
        width 100%
        height 100%
        z-index 10
      &::after
        content ""
        position absolute
        background-image url("/assets/img/button-play.svg")
        background-repeat no-repeat
        background-position 50% 50%
        z-index 11
        width 100%
        height 100%
        top 0
        left 0
      img
        vertical-align bottom
        height 100%
        width 100%
        object-fit cover
    &-container
      display none
      border-radius 12px
      iframe
        width 100%
        height 100%
        min-height 404px
        border-radius 12px
    &.is-play
      .movie-poster
        display none
      .movie-container
        display block


#functions
  &.section-bg
    background-position 0 -140px
    +breakpoint(sm)
      background-position 0 0
      background-size contain
      background-color bg-light-blue
  .columns
    margin-top 0
    +breakpoint(sm)
      padding-top 0
      margin-bottom 10px
    .column
      padding 2rem
      display flex
      align-items stretch
      &.has-text-centered
        justify-content center
      +breakpoint(sm)
        padding 12px 5px
        &:first-child
          padding-top 0
      .card
        border-radius 8px
        &-content
          padding 4rem 2.2rem
          +breakpoint(sm)
            padding 40px 2.2rem
          .content
            margin-bottom 24px
            .image
              img
                width 80px
                height 80px
              +breakpoint(sm)
                max-height 80px
                img
                  height 100%
                  object-fit contain
          .media
            &-content
              h2
                font-size-px(fonr-size, 18)
                margin-bottom 24px
                min-height 54px
                display flex
                align-items center
                justify-content center
                +breakpoint(sm)
                  min-height auto
              p
                font-size-px(fonr-size, 15)
                &.notice
                  margin-top 1.6rem
                  line-height 1.5

#introduction
  .columns
    margin-top 0
    padding 0
    counter-reset number
    +breakpoint(sm)
      padding 0 0.25rem 20px
    .column
      padding 20px
      display flex
      align-items stretch
      +breakpoint(sm)
        padding 12px 5px
        &:first-child
          padding-top 0
      &:nth-child(1)
        .card
          background bg-dark-blue
          h2
            &::before
              background primary-color
      &:nth-child(2)
        .card
          background bg-dark-blue
          h2
            &::before
              background primary-color
      &:nth-child(3)
        .card
          background bg-dark-blue
          h2
            &::before
              background primary-color
      &:last-child
        padding 20px 10px 0
      .card
        border-radius 8px
        &-content
          padding 2.2rem
        h2
          display flex
          line-height 32px
          margin-bottom 16px
          &::before
            counter-increment number
            content counter(number)
            icon(32)
     .button
        width 100%
#flow
  &.section-bg
    background-position 0 -1240px
    +breakpoint(sm)
      background-position 0 0
  .columns
    margin-top 0
    padding-top 20px
    +breakpoint(sm)
      padding-top 0
    .column
      padding-top 0
      .card
        border-radius 12px
        height 100%
        &-content
          padding 2.2rem
          &.option
            border-top 1px solid line-color
            h4
              font-family Overpass
              font-weight bold
              background white
              border 2px solid primary-color
              color primary-color
              text-transform uppercase
              height 32px
              line-height 29px
              border-radius 16px
              width 80px
              text-align center
              margin-top -40px
              margin-left auto
              margin-right auto
              margin-bottom 24px
          .media
            &-content
              h2
                font-size-px(font-size, 24)
                margin-bottom 24px
              .notice
                margin-bottom 24px
                line-height 1.5
              ul
                counter-reset number
                li
                  margin-bottom 3rem
                  position relative
                  &:last-child
                    &::before
                      display none
                  &::before
                    position absolute
                    left 15px
                    top 38px
                    width 2px
                    content ""
                    height 100%
                    background url("/assets/img/line-dotted.svg")
                  h3
                    margin-bottom 1rem
                    display flex
                    line-height 32px
                    &::before
                      counter-increment number
                      content counter(number)
                      icon(32)
                      background white
                      border 2px solid primary-color
                      color primary-color
                      line-height 28px
                  p
                    padding-left 48px
#faq
  &.section
    +breakpoint(sm)
      3rem 0
  .container
    +breakpoint(sm)
      padding 0
  .columns
    padding-top 20px
    margin-top 0
    +breakpoint(sm)
      padding-top 0
    .column
      padding-top 0
      +breakpoint(sm)
        padding 0
  .card
    border-bottom 1px solid line-color
    &:first-child
      border-top 1px solid line-color
    &-content
      padding 2rem
  .accordion
    &.is-active
      .accordion-header
        margin-bottom 30px
        display flex
        justify-content space-between
        .toggle-icon
          margin 1px -5px 0 0
          width 18px
          height 18px
          position relative
          cursor pointer
          span
            position absolute
            top 50%
            left 0
            height 0.2rem
            width 100%
            display block
            background primary-text
            border-radius 3px
            &:nth-child(1)
              transform rotate(45deg)
            &:nth-child(2)
              transform rotate(-45deg)
      .accordion-body
        height auto
        opacity 1
  .accordion-header
    transition all 0.2s ease-out
    margin-bottom 0
    display flex
    justify-content space-between
    .toggle-icon
      margin 1px -5px 0 0
      width 18px
      height 18px
      position relative
      cursor pointer
      span
        position absolute
        top 60%
        left 3px
        height .2rem
        width 67%
        display block
        background primary-text
        border-radius 3px
        transition all 0.2s ease-out
        +breakpoint(sm)
          top 70%
        &:nth-child(1)
          transform rotate(45deg) translateX(-5px)
        &:nth-child(2)
          transform rotate(-45deg) translateX(5px)
  .accordion-body
    height 0
    opacity 0
    overflow hidden
    transition all 0.2s ease-out
  .question
    font-weight bold
    display flex
    line-height 24px
    cursor pointer
    margin-right 1.4rem
    +breakpoint(sm)
      width 90%
    &::before
      content ""
      icon(24)
      background url("/assets/img/icon-q.svg")
      background-repeat no-repeat
      background-size contain
    span
      +breakpoint(sm)
        width 90%
  .answer
    display flex
    line-height 25px
    span
      width 90%
    &::before
      content ""
      icon(24)
      background url("/assets/img/icon-a.svg")
      background-repeat no-repeat
      background-size contain
#contact
  background white
  position sticky
  bottom 0
  border-top 1px solid line-color
  border-bottom 1px solid line-color
  padding 40px 0
  z-index 999
  transition padding 0.2s ease-out
  .button
    width 100%
  &.fixed
    padding 24px 0
  .contact
    &-after
      display block
      width 100%
      height 30px
      background transparent
      position absolute
      bottom -32px
.footer
  padding 80px 0 42px
  background-color primary-color
  background-size cover
  ul
    display flex
    justify-content center
    flex-wrap wrap
    padding 0
    margin 0
    +breakpoint(sm)
      display flex
      padding 0 3rem
    li
      list-style none
      font-size-px(font-size, 13)
      margin-right 24px
      margin-top 0
      +breakpoint(sm)
        width 50%
        margin-bottom 1rem
        margin-right 0
        text-align left
      &:last-child
        margin-right 0
      &:after
        padding 0 24px
        +breakpoint(sm)
          display none
      &:last-child
        &:after
          display none
      a
        color white
        cursor pointer
        font-weight bold
#information
  &.section
    +breakpoint(sm)
      3rem 0
  .container
    &.main
      +breakpoint(sm)
        padding 0
  .columns
    .column
      +breakpoint(sm)
        padding 0
  .card
    &-content
      text-align center
      +breakpoint(sm)
        padding 0
        margin 0 20px
        border 1px solid line-color
        border-radius 12px
        iframe
          border-radius 12px
  .list
    &-container
      margin-bottom 24px
    &-item
      padding 8px 0
      margin-bottom 24px
      border-bottom 1px solid line-color
      display flex
      align-items center
      +breakpoint(sm)
        flex-wrap wrap
        padding adjust-sp(16)
      time
        font-size-px(font-size, 14)
        font-weight 700
      span
        background-color primary-color
        color white
        margin 0 32px 0 8px
        font-size-px(font-size, 10)
        font-weight 700
        padding 4px 16px
      p
        font-size-px(font-size, 15)
        font-weight 500
        +breakpoint(sm)
          display block
          width 100%
          margin-top adjust-sp(8)
          font-size adjust-sp(14)
.copyright
  margin-top 42px
