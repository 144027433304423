.container
  +breakpoint(sm)
    padding 0 1rem
.slider
  &:focus
    outline none
  &:focus-ring
    outline none
.slider-navigation-next
  background-image url("/assets/img/right-arrow.svg")
  box-shadow none
  background-color transparent
.slider-navigation-previous
  background-image url("/assets/img/left-arrow.svg")
  box-shadow none
  background-color transparent
.section
  padding 40px 0 64px
  +breakpoint(sm)
    padding 40px 1rem
.navbar
  max-width 1280px
  margin 0 auto
.navbar-item
  font-size 14px
.navbar-burger
  color primary-color
  +breakpoint(sm)
    color primary-color
.navbar-burger.is-active
  +breakpoint(sm)
    position absolute
    right 24px
    z-index 10000
    color #fff
    background none
.navbar-menu.is-active
  +breakpoint(sm)
    background primary-gradation
    top 0
    position fixed
    width 100%
    display flex
    justify-content center
    align-items center
  .navbar-item
    +breakpoint(sm)
      height 16%
      display flex
      justify-content center
      align-items center
      font-size-px(font-size, 12)
      color #fff
      font-weight 700
  .navbar-end
    +breakpoint(sm)
      height 80%
.is-boxed
  margin 1rem auto
  max-width 1280px
  width 94%
  padding 4rem 0.75rem
  color primary-color
  +breakpoint(sm)
    width 100%
    margin-bottom 2rem
  &.bg-white
    background #fff
    border 0
  &.border-white
    border 1px solid #fff
    color #fff
.is-bold
  font-weight 700
.card
  box-shadow none
.subtitle
  color primary-color
.title:not(.is-spaced)+.subtitle
  margin-top -1.8rem
.page-top
  padding 40px 0 80px
  text-align center
.footer
  padding 3rem
  background primary-color
  color #fff
