@keyframes toLeftScroll
  0%
    background-position 0 0
  100%
    background-position -3108px 0

@keyframes toRightScroll
  0%
    background-position -3108px 0
  100%
    background-position 0 0

@keyframes slideIn
  0%
    opacity 0
    transform translateX(24px)
  100%
    opacity 1
    transform translateX(0)

@keyframes menuIn
  0%
    display none
    opacity 0
    width 0
    height 0
    border-radius 100%
    transform: translate(50%, -50%)
  1%
    display block
    opacity 1
  50%
    display block
    width 3000px
    height 3000px
    border-radius 100%
    transform: translate(50%, -50%)
  100%
    display block
    width 100%
    height 100%
    border-radius 0
    transform: translate(0, 0)

@keyframes menuInSp
  0%
    display none
    opacity 0
    width 0
    height 0
    border-radius 100%
    transform: translate(50%, -50%)
  1%
    display block
    opacity 1
  50%
    display block
    width 1000px
    height 1000px
    border-radius 100%
    transform: translate(50%, -50%)
  100%
    display block
    width 100%
    height 100%
    border-radius 0
    transform: translate(0, 0)
