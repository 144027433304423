#form
  &.section
    min-height calc(100vh - 226px)
    padding 140px 1rem 3rem
  .title
    margin-bottom 24px
  .description
    margin-bottom 24px
    font-weight 500
    font-size-px(font-size, 14)
    a
      color primary-text
      border-bottom 1px solid primary-text
  .auxiliary
    color primary-red
    font-size-px(font-size, 14)
    font-weight 700
  .form-container
    padding 60px 0
    &.is-sent
      form
        display none
      .form-container-sent
        display block
    &-sent
      display none
      text-align center
      .title
        color primary-color
    .loading
      display none
      height 100vh
      width 100vw
      position fixed
      top 0
      left 0
      z-index 1000
      &.is-loading
        display block
      &::after
        background-color black
        content ""
        width 100vw
        height 100vh
        position fixed
        z-index 1000
        opacity .4
      &::before
        background-repeat no-repeat
        background-position center center
        background-size 80px 80px
        background-image url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2238%22%20height%3D%2238%22%20viewBox%3D%220%200%2038%2038%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%3Cdefs%3E%20%3ClinearGradient%20x1%3D%228.042%25%22%20y1%3D%220%25%22%20x2%3D%2265.682%25%22%20y2%3D%2223.865%25%22%20id%3D%22a%22%3E%20%3Cstop%20stop-color%3D%22%23fff%22%20stop-opacity%3D%220%22%20offset%3D%220%25%22%2F%3E%20%3Cstop%20stop-color%3D%22%23fff%22%20stop-opacity%3D%22.631%22%20offset%3D%2263.146%25%22%2F%3E%20%3Cstop%20stop-color%3D%22%23fff%22%20offset%3D%22100%25%22%2F%3E%20%3C%2FlinearGradient%3E%20%3C%2Fdefs%3E%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%20%3Cg%20transform%3D%22translate(1%201)%22%3E%20%3Cpath%20d%3D%22M36%2018c0-9.94-8.06-18-18-18%22%20id%3D%22Oval-2%22%20stroke%3D%22url(%23a)%22%20stroke-width%3D%222%22%3E%20%3CanimateTransform%20attributeName%3D%22transform%22%20type%3D%22rotate%22%20from%3D%220%2018%2018%22%20to%3D%22360%2018%2018%22%20dur%3D%220.9s%22%20repeatCount%3D%22indefinite%22%20%2F%3E%20%3C%2Fpath%3E%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%2236%22%20cy%3D%2218%22%20r%3D%221%22%3E%20%3CanimateTransform%20attributeName%3D%22transform%22%20type%3D%22rotate%22%20from%3D%220%2018%2018%22%20to%3D%22360%2018%2018%22%20dur%3D%220.9s%22%20repeatCount%3D%22indefinite%22%20%2F%3E%20%3C%2Fcircle%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E')
        content ""
        width 100vw
        height 100vh
        position fixed
        z-index 1001
  .field
    margin-bottom 24px
    .button
      width 100%
    .label
      font-size-px(font-size, 14)
      sup
        color primary-red
    .input
      min-height 50px
      font-size-px(font-size, 16)
    .textarea
      font-size-px(font-size, 16)
    .help
      font-size-px(font-size, 12)
      font-weight 500
      em
        font-style normal
        display block
    &-form
      &-status
        text-align center
        font-size-px(font-size, 12)
        font-weight 500
        padding 8px 0
  .entry-policy
    max-height 200px
    padding 16px
    border 1px solid line-color
    overflow-y scroll
    margin-bottom 24px
    p
      font-size-px(font-size, 12)
    h2
      font-size-px(font-size, 14)
      margin-top 24px
      margin-bottom 12px
    ol
      padding-top 8px
      padding-left 14px
      line-height 1.4
      margin-bottom 14px
      li
        font-size-px(font-size, 12)
        color primary-text
        margin-bottom 8px
        padding 0
        line-height 1.4
