@font-face
  font-family NotoSansCJKjp
  font-style normal
  font-weight 400
  src url('../font/NotoSansCJKjp-Regular.otf')
  src local('Noto Sans CJK JP Regular'), url('../font/NotoSansCJKjp-Regular.otf?#iefix') format('opentype'), url('../font/NotoSansCJKjp-Regular.woff') format('woff')

@font-face
  font-family NotoSansCJKjp
  font-style normal
  font-weight 500
  src url('../font/NotoSansCJKjp-Medium.otf')
  src local('Noto Sans CJK JP Medium'), url('../font/NotoSansCJKjp-Medium.otf?#iefix') format('opentype'), url('../font/NotoSansCJKjp-Medium.woff') format('woff')

@font-face
  font-family NotoSansCJKjp
  font-style normal
  font-weight 700
  src url('../font/NotoSansCJKjp-Bold.otf')
  src local('Noto Sans CJK JP Bold'), url('../font/NotoSansCJKjp-Bold.otf?#iefix') format('opentype'), url('../font/NotoSansCJKjp-Bold.woff') format('woff')

@font-face
  font-family NotoSansJP-Medium
  font-style normal
  src url('../font/NotoSansJP-Medium.otf')
  src local('Noto Sans JP Medium'), url('../font/NotoSansJP-Medium.otf?#iefix') format('opentype'), url('../font/NotoSansJP-Medium.woff') format('woff')

@font-face
  font-family NotoSansJP-Black
  font-style normal
  src url('../font/NotoSansJP-Black.otf')
  src local('Noto Sans JP Black'), url('../font/NotoSansJP-Black.otf?#iefix') format('opentype'), url('../font/NotoSansJP-Black.woff') format('woff')

@font-face
  font-family NotoSansCJKjp-Black
  font-style normal
  src url('../font/NotoSansJP-Black.otf')
  src local('Noto Sans JP Black'), url('../font/NotoSansJP-Black.otf?#iefix') format('opentype'), url('../font/NotoSansJP-Black.woff') format('woff')

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap')
@import url('https://fonts.googleapis.com/css?family=Overpass:400,700')
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,700&family=Sriracha&display=swap')
