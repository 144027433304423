font-size-px(prop, font_size)
  {prop}: unit(font_size, 'px')
  {prop}: unit(font_size / 10, 'rem')

breakpoint(bpName)
  for name in bpName
    if name == 'xs'
      @media only screen and (max-width: screen-xs)
        {block}
    if name == 'sm'
      @media only screen and (max-width: screen-sm)
        {block}
    if name == 'md'
      @media only screen and (max-width: screen-md)
        {block}
    if name == 'lg'
      @media only screen and (max-width: screen-lg)
        {block}

adjust-pc(size)
  return 'calc(%s / 1280 * 100vw)' % size

adjust-sp(size)
  return 'calc(%s / 375 * 100vw)' % size

retina-bg($url, $ext = png, $width = 100, $height = 100, $scale = 3)
  $dpi = 96
  $descender = 0.5

  background-image url($url'.'$ext)
  background-size $width $height
  background-repeat no-repeat

  if $scale > 1
    for $i in 2..$scale
      @media (min-resolution ($i - $descender) * ($dpi)dpi), (min-resolution ($i - $descender)dppx)
        background-image url($url'@'$i'x.'$ext)

browser-pc-fix-prop(prop, size)
  {prop}: 'calc(%s / 1280 * 100vw)' % size

browser-sp-fix-prop(prop, size)
  {prop}: 'calc(%s / 768 * 100vw)' % size
